@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: #18181b;
}